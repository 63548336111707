import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Menu, X, Phone, MessageCircle, ChevronDown} from 'lucide-react';
import logo from '../../Assets/images/logo.webp'

export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    // Manejo del scroll para cambiar el estilo de la navbar
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 20) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const navLinks = [
        {text: "Inicio", path: "/"},
        {text: "Sobre Nosotros", path: "/sobre-mantenimiento-de-lavadoras-ariza"},
        {text: "Servicios", path: "/servicios-de-mantenimiento-lavadoras"},
        {text: "Preguntas Frecuentes", path: "/preguntas-frecuentes"}
    ];

    return (
        <header className={` fixed w-full top-0 z-50 transition-all duration-300 ${
            isScrolled ? 'bg-white shadow-lg' : 'bg-white/80 backdrop-blur-sm'
        }`}>
            {/* Top Bar */}
            <div className="bg-blue-900 text-white py-1 px-6 text-sm hidden md:block">
                <div className="max-w-7xl mx-auto flex justify-between items-center">
                    <div className="flex items-center space-x-4">
                        <span className="flex items-center">
                            <Phone className="w-4 h-4 mr-2"/>
                            317 369 3893
                        </span>
                        <span>|</span>
                        <span>Servicio 24/7 en toda Bogotá</span>
                    </div>
                    <button
                        className="flex items-center space-x-2 bg-green-500 hover:bg-green-600 px-4 py-1 rounded-full text-sm transition-colors">
                        <MessageCircle className="w-4 h-4"/>
                        <span>WhatsApp Directo</span>
                    </button>
                </div>
            </div>
            <div className="flex items-center justify-center">
                <p className="text-center py-2 text-lg font-bold text-blue-800">Se Renta Esta Web</p>
            </div>

            {/* Main Navbar */}
            <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between items-center h-16">
                    {/* Logo */}
                    <div className="flex-shrink-0">
                        <Link to="/" className="flex items-center">
                            <img
                                src={logo}
                                alt="Logo"
                                className="h-16 w-auto"
                            />
                        </Link>
                    </div>

                    {/* Desktop Navigation */}
                    <div className="hidden md:flex md:items-center md:space-x-8">
                        {navLinks.map((link, index) => (
                            <Link
                                key={index}
                                to={link.path}
                                className="text-gray-700 hover:text-blue-600 px-3 py-2 text-sm font-medium transition-colors relative group"
                            >
                                {link.text}
                                <span
                                    className="absolute bottom-0 left-0 w-full h-0.5 bg-blue-600 transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left"></span>
                            </Link>
                        ))}
                        <button
                            className="bg-blue-600 text-white px-6 py-2 rounded-lg text-sm font-medium hover:bg-blue-700 transition-colors ml-4">
                            Contactar
                        </button>
                    </div>

                    {/* Mobile menu button */}
                    <div className="md:hidden">
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:text-blue-600 hover:bg-blue-50 focus:outline-none transition-colors"
                        >
                            {isOpen ? (
                                <X className="h-6 w-6"/>
                            ) : (
                                <Menu className="h-6 w-6"/>
                            )}
                        </button>
                    </div>
                </div>

                {/* Mobile Navigation */}
                <div
                    className={`md:hidden transition-all duration-300 ease-in-out ${
                        isOpen
                            ? 'max-h-96 opacity-100 visible'
                            : 'max-h-0 opacity-0 invisible'
                    }`}
                >
                    <div className="px-2 pt-2 pb-3 space-y-1 bg-white rounded-lg shadow-lg mt-2">
                        {navLinks.map((link, index) => (
                            <Link
                                key={index}
                                to={link.path}
                                onClick={() => setIsOpen(false)}
                                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-blue-600 hover:bg-blue-50 transition-colors"
                            >
                                {link.text}
                            </Link>
                        ))}
                        <div className="pt-4">
                            <a href="https://wa.link/wo9nty">
                                <button
                                    className="w-full bg-blue-600 text-white px-4 py-2 rounded-lg text-sm font-medium hover:bg-blue-700 transition-colors">
                                    Contactar
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
}