import React from 'react';
import { Phone, Mail, MapPin, Clock, Shield, Facebook, Instagram, Linkedin } from 'lucide-react';
import logo from '../../Assets/images/logo.webp'

export default function Footer() {
    return (
        <footer className="bg-gradient-to-b from-blue-900 to-blue-950 text-white py-16">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
                    {/* Company Info Section */}
                    <div className="space-y-6">
                        <div className="flex items-center space-x-2">
                            {/* Logo container with fixed size */}
                            <div className="w-40 h-16 bg-white rounded-lg flex items-center justify-center">
                                <img
                                    src={logo}
                                    alt="Logo empresa"
                                    className="w-32 h-auto object-contain"
                                />
                            </div>
                        </div>
                        <p className="text-blue-100 text-sm leading-relaxed">
                            Expertos en reparación y mantenimiento de lavadoras. Servicio técnico profesional con más de 15 años de experiencia.
                        </p>
                        <div className="flex items-center space-x-4">
                            <Facebook className="w-5 h-5 text-blue-300 hover:text-white cursor-pointer transition-colors" />
                            <Instagram className="w-5 h-5 text-blue-300 hover:text-white cursor-pointer transition-colors" />
                            <Linkedin className="w-5 h-5 text-blue-300 hover:text-white cursor-pointer transition-colors" />
                        </div>
                    </div>

                    {/* Contact Info */}
                    <div className="space-y-6">
                        <h3 className="text-xl font-bold">Información de Contacto</h3>
                        <ul className="space-y-4">
                            <li className="flex items-start space-x-3">
                                <MapPin className="w-5 h-5 text-blue-300 flex-shrink-0 mt-1" />
                                <span className="text-blue-100">Carrera 92 #129a - 22, Oficina 301, Bogotá</span>
                            </li>
                            <li className="flex items-center space-x-3">
                                <Phone className="w-5 h-5 text-blue-300" />
                                <span className="text-blue-100">317 369 3893</span>
                            </li>
                            <li className="flex items-center space-x-3">
                                <Mail className="w-5 h-5 text-blue-300" />
                                <span className="text-blue-100">servicio@empresa.com</span>
                            </li>
                            <li className="flex items-center space-x-3">
                                <Clock className="w-5 h-5 text-blue-300" />
                                <span className="text-blue-100">Lun - Dom: 7:00 AM - 8:00 PM</span>
                            </li>
                        </ul>
                    </div>

                    {/* Services Section */}
                    <div className="space-y-6">
                        <h3 className="text-xl font-bold">Nuestros Servicios</h3>
                        <ul className="space-y-3">
                            <li className="text-blue-100 hover:text-white transition-colors cursor-pointer">
                                Reparación de Lavadoras
                            </li>
                            <li className="text-blue-100 hover:text-white transition-colors cursor-pointer">
                                Mantenimiento Preventivo
                            </li>
                            <li className="text-blue-100 hover:text-white transition-colors cursor-pointer">
                                Instalación de Equipos
                            </li>
                            <li className="text-blue-100 hover:text-white transition-colors cursor-pointer">
                                Venta de Repuestos
                            </li>
                            <li className="text-blue-100 hover:text-white transition-colors cursor-pointer">
                                Servicio de Emergencia
                            </li>
                        </ul>
                    </div>

                    {/* Certification Section */}
                    <div className="space-y-6">
                        <h3 className="text-xl font-bold">Certificaciones</h3>
                        <div className="bg-white p-4 rounded-lg">
                            <img
                                src={logo}
                                alt="Certificación de servicio"
                                className="w-full h-auto object-contain"
                            />
                        </div>
                        <div className="flex items-center space-x-2">
                            <Shield className="w-5 h-5 text-green-400" />
                            <span className="text-sm text-blue-100">Servicio Técnico Certificado</span>
                        </div>
                    </div>
                </div>

                {/* Bottom Bar */}
                <div className="mt-16 pt-8 border-t border-blue-800">
                    <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
                        <p className="text-blue-200 text-sm">
                            © {new Date().getFullYear()} Todos los derechos reservados
                        </p>
                        <p className="text-blue-200 text-sm">
                            Desarrollado por{' '}
                            <a
                                href="https://portfolio-virid-mu-25.vercel.app/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-300 hover:text-white transition-colors"
                            >
                                Fabian Tinjaca
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}