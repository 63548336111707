import React from 'react';
import { Wrench, CheckCircle, AlertTriangle, Clock, Search, Shield, PhoneCall, Settings, RefreshCw, Droplets, Zap, HelpCircle } from 'lucide-react';
import lg from '../../Assets/images/lgft.webp'
import PricingSection from "./pricing";
import {Helmet} from "react-helmet-async";

const ServicesPage = () => {
    const mainServices = [
        {
            title: "Mantenimiento Preventivo",
            description: "Servicio completo de limpieza y mantenimiento preventivo",
            price: "Desde $80.000",
            includes: [
                "Limpieza profunda del agitador",
                "Limpieza de filtros y atrapa motas",
                "Extracción y limpieza de tinas",
                "Eliminación de óxido y residuos",
                "Limpieza de bombas de agua",
                "Ajuste de correa y mecanismos",
                "Revisión del sistema eléctrico",
                "Diagnóstico preventivo completo"
            ],
            icon: <Wrench className="w-6 h-6" />
        },
        {
            title: "Reparación de Averías",
            description: "Solución a problemas específicos de su lavadora",
            price: "Según diagnóstico",
            includes: [
                "Diagnóstico profesional",
                "Reparación de fallas eléctricas",
                "Reparación de fallas mecánicas",
                "Cambio de repuestos originales",
                "Reparación de fugas",
                "Corrección de ruidos anormales",
                "Reparación de panel de control",
                "Garantía en reparaciones"
            ],
            icon: <Wrench className="w-6 h-6" />
        },
        {
            title: "Limpieza Especializada",
            description: "Limpieza profunda de todos los componentes",
            price: "Desde $70.000",
            includes: [
                "Desmontaje completo",
                "Limpieza de tambor",
                "Limpieza de dispensadores",
                "Eliminación de malos olores",
                "Limpieza de sellos y empaques",
                "Desinfección completa",
                "Limpieza de filtros",
                "Eliminación de cal y sarro"
            ],
            icon: <Droplets className="w-6 h-6" />
        }
    ];

    const commonRepairs = [
        {
            problem: "No Enciende",
            solutions: [
                "Revisión de conexiones eléctricas",
                "Verificación de tarjeta madre",
                "Diagnóstico de panel de control",
                "Comprobación de voltaje"
            ],
            price: "Desde $60.000"
        },
        {
            problem: "No Centrifuga",
            solutions: [
                "Revisión de correa de transmisión",
                "Verificación del motor",
                "Diagnóstico de sensor de carga",
                "Comprobación de sistema de drenaje"
            ],
            price: "Desde $70.000"
        },
        {
            problem: "Fugas de Agua",
            solutions: [
                "Revisión de mangueras",
                "Cambio de sellos y empaques",
                "Verificación de bomba de agua",
                "Reparación de tina"
            ],
            price: "Desde $65.000"
        },
        {
            problem: "Ruidos Extraños",
            solutions: [
                "Revisión de rodamientos",
                "Verificación de amortiguadores",
                "Ajuste de piezas sueltas",
                "Nivelación de la máquina"
            ],
            price: "Desde $75.000"
        }
    ];

    const brands = [
        "LG", "Samsung", "Whirlpool", "Mabe", "Electrolux", "Haceb",
        "Centrales", "Challenger", "General Electric", "Frigidaire"
    ];

    return (
        <main className="bg-gray-50">
            <Helmet>
                <title>Servicios de Mantenimiento de Lavadoras - Mantenimiento Lavadoras Ariza</title>
                <meta name="description" content="Descubre nuestros servicios de mantenimiento y reparación de lavadoras. Garantizamos un servicio rápido y eficaz para todas las marcas y modelos." />
                <meta name="keywords" content="mantenimiento de lavadoras, reparación de lavadoras, servicios de lavadoras, lavadoras a domicilio, mantenimiento electrodomésticos" />
                <link rel="canonical" href="https://mantenimientolavadorasariza.com/servicios-de-mantenimiento-lavadoras" />
            </Helmet>
            {/* Hero Section */}
            <section className="relative bg-gradient-to-r from-blue-600 to-blue-800 py-20">
                <div className="container mx-auto px-4">
                    <div className="max-w-3xl mx-auto text-center text-white">
                        <h1 className="text-4xl md:text-5xl font-bold my-16">
                            Servicios de Mantenimiento y Reparación de Lavadoras
                        </h1>
                        <p className="text-xl mb-8">
                            Expertos en mantenimiento, reparación y servicio técnico de lavadoras.
                            Servicio a domicilio en todo Bogotá.
                        </p>
                        <div className="flex flex-col sm:flex-row gap-4 justify-center">
                            <button className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-blue-50 transition duration-300 flex items-center gap-2">
                                <PhoneCall className="w-5 h-5" />
                                Solicitar Servicio
                            </button>
                            <a href="https://wa.link/wo9nty">
                                <button
                                    className="bg-transparent border-2 border-white text-white px-8 py-3 rounded-lg font-semibold hover:bg-white/10 transition duration-300 flex items-center gap-2">
                                    <HelpCircle className="w-5 h-5"/>
                                    Consultar Precios
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            {/* Emergency Banner */}
            <div className="bg-yellow-500 text-white py-3">
                <div className="container mx-auto px-4">
                    <p className="flex items-center justify-center gap-2">
                    <AlertTriangle className="w-5 h-5" />
                        ¿Emergencia con su lavadora? Servicio técnico disponible 24/7
                        <span className="font-bold ml-2">Llame ahora: +57 314 465 8752</span>
                    </p>
                </div>
            </div>

            <PricingSection />

            {/* Common Problems Section */}
            <section className="py-16 bg-white">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-12">Reparaciones Comunes</h2>
                    <div className="grid md:grid-cols-2 gap-8">
                        {commonRepairs.map((item, index) => (
                            <div key={index} className="bg-gray-50 p-6 rounded-xl">
                                <div className="flex items-center gap-3 mb-4">
                                    <Settings className="w-6 h-6 text-blue-600" />
                                    <h3 className="text-xl font-semibold">{item.problem}</h3>
                                </div>
                                <ul className="space-y-2 mb-4">
                                    {item.solutions.map((solution, i) => (
                                        <li key={i} className="flex items-center gap-2 text-gray-600">
                                            <CheckCircle className="w-4 h-4 text-green-500" />
                                            {solution}
                                        </li>
                                    ))}
                                </ul>
                                <p className="text-blue-600 font-semibold">{item.price}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Service Process */}
            <section className="py-16 bg-gray-50">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-12">Nuestro Proceso de Servicio</h2>
                    <div className="grid md:grid-cols-4 gap-8">
                        <div className="text-center">
                            <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4">
                                <PhoneCall className="w-8 h-8 text-blue-600" />
                            </div>
                            <h3 className="font-semibold mb-2">1. Contacto</h3>
                            <p className="text-gray-600">Llámenos o envíe un mensaje para programar su servicio</p>
                        </div>
                        <div className="text-center">
                            <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4">
                                <Search className="w-8 h-8 text-blue-600" />
                            </div>
                            <h3 className="font-semibold mb-2">2. Diagnóstico</h3>
                            <p className="text-gray-600">Realizamos una evaluación completa de su lavadora</p>
                        </div>
                        <div className="text-center">
                            <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4">
                                <Wrench className="w-8 h-8 text-blue-600" />
                            </div>
                            <h3 className="font-semibold mb-2">3. Reparación</h3>
                            <p className="text-gray-600">Solucionamos el problema con repuestos originales</p>
                        </div>
                        <div className="text-center">
                            <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4">
                                <Shield className="w-8 h-8 text-blue-600" />
                            </div>
                            <h3 className="font-semibold mb-2">4. Garantía</h3>
                            <p className="text-gray-600">Respaldamos nuestro trabajo con garantía por escrito</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Brands Section */}
            <section className="py-16 bg-white">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-12">Marcas que Reparamos</h2>
                    <div className="grid grid-cols-2 md:grid-cols-5 gap-6">
                        {brands.map((brand, index) => (
                            <div key={index} className="bg-gray-50 p-4 rounded-lg text-center">
                                <p className="font-semibold">{brand}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Why Choose Us */}
            <section className="py-16 bg-gray-50">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-12">¿Por Qué Elegirnos?</h2>
                    <div className="grid md:grid-cols-3 gap-8">
                        <div className="bg-white p-6 rounded-xl shadow-lg">
                            <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                                <Clock className="w-6 h-6 text-blue-600" />
                            </div>
                            <h3 className="text-xl font-semibold mb-2">Respuesta Rápida</h3>
                            <p className="text-gray-600">Atendemos su solicitud en menos de 24 horas</p>
                        </div>
                        <div className="bg-white p-6 rounded-xl shadow-lg">
                            <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                                <Shield className="w-6 h-6 text-blue-600" />
                            </div>
                            <h3 className="text-xl font-semibold mb-2">Garantía Total</h3>
                            <p className="text-gray-600">Garantía por escrito en repuestos y mano de obra</p>
                        </div>
                        <div className="bg-white p-6 rounded-xl shadow-lg">
                            <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                                <Wrench className="w-6 h-6 text-blue-600" />
                            </div>
                            <h3 className="text-xl font-semibold mb-2">Técnicos Certificados</h3>
                            <p className="text-gray-600">Personal altamente capacitado y con experiencia</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* CTA Section */}
            <section className="py-16 bg-blue-600">
                <div className="container mx-auto px-4 text-center">
                    <h2 className="text-3xl font-bold text-white mb-6">
                        ¿Necesita ayuda con su lavadora?
                    </h2>
                    <p className="text-xl text-blue-100 mb-8">
                        Contáctenos ahora y reciba atención inmediata.
                        Primera visita de diagnóstico sin costo.
                    </p>
                    <div className="flex flex-col sm:flex-row gap-4 justify-center">
                        <button className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-blue-50 transition duration-300">
                            Agendar Servicio
                        </button>
                        <button className="bg-transparent border-2 border-white text-white px-8 py-3 rounded-lg font-semibold hover:bg-white/10 transition duration-300">
                            WhatsApp
                        </button>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default ServicesPage;