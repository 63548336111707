import React from 'react';
import { Phone, MessageCircle } from 'lucide-react';

const ContactButtons = () => {
    return (
        <div>
            <a
                rel="nofollow"
                href="tel:+573173693893"
                className="fixed bottom-[110px] right-[18px] md:bottom-[130px] md:right-[19px] overflow-hidden bg-blue-500 p-4 rounded-full hover:bg-blue-600 transition-colors"
            >
                <Phone
                    size={40}
                    className="text-white"
                />
            </a>
            <a
                rel="nofollow"
                href="https://wa.link/wo9nty"
                className="fixed bottom-5 right-[19px] overflow-hidden animate-bounce bg-green-500 p-4 rounded-full hover:bg-green-600 transition-colors"
            >
                <MessageCircle
                    size={40}
                    className="text-white"
                />
            </a>
        </div>
    );
};

export default ContactButtons;