import React from 'react';
import { Shield, Star, Clock, CheckCircle, Award, Zap, Wrench, ThumbsUp } from 'lucide-react';

const PricingSection = () => {
    const pricingCards = [
        {
            title: "Mantenimiento Preventivo",
            price: "80.000",
            highlight: "¡Más Popular!",
            description: "Mantenimiento completo para prevenir daños futuros",
            features: [
                "Limpieza profunda del sistema",
                "Revisión completa de componentes",
                "Ajuste de mecanismos",
                "Diagnóstico preventivo",
            ],
            benefits: "Ahorre hasta 60% en reparaciones futuras",
            icon: <Wrench className="w-6 h-6" />,
            timeEstimate: "2-3 horas",
            warranty: "3 meses",
        },
        {
            title: "Reparación Premium",
            price: "Desde 120.000",
            highlight: "Garantía Extendida",
            description: "Solución definitiva para cualquier avería",
            features: [
                "Diagnóstico profesional completo",
                "Repuestos originales garantizados",
                "Reparación de cualquier falla",
                "Pruebas de funcionamiento",
            ],
            benefits: "Respaldo total con garantía escrita",
            icon: <Star className="w-6 h-6" />,
            timeEstimate: "Mismo día",
            warranty: "6 meses",
        },
        {
            title: "Limpieza Especializada",
            price: "70.000",
            highlight: "Mejor Valor",
            description: "Renovación completa de su lavadora",
            features: [
                "Eliminación de olores",
                "Desinfección profunda",
                "Limpieza de filtros y ductos",
                "Tratamiento antical",
            ],
            benefits: "Su lavadora como nueva",
            icon: <Zap className="w-6 h-6" />,
            timeEstimate: "1-2 horas",
            warranty: "2 meses",
        }
    ];

    return (
        <section className="py-16 bg-gradient-to-b from-white to-blue-50">
            <div className="container mx-auto px-4">
                <div className="text-center mb-12">
                    <h2 className="text-4xl font-bold mb-4">Planes de Servicio</h2>
                    <p className="text-xl text-gray-600 mb-6">Soluciones profesionales adaptadas a su necesidad</p>
                    <div className="flex items-center justify-center gap-4 mb-8">
                        <div className="flex items-center gap-2">
                            <Shield className="w-5 h-5 text-green-500" />
                            <span>Garantía por escrito</span>
                        </div>
                        <div className="flex items-center gap-2">
                            <Clock className="w-5 h-5 text-blue-500" />
                            <span>Servicio mismo día</span>
                        </div>
                        <div className="flex items-center gap-2">
                            <Award className="w-5 h-5 text-yellow-500" />
                            <span>Técnicos certificados</span>
                        </div>
                    </div>
                </div>

                <div className="grid md:grid-cols-3 gap-8">
                    {pricingCards.map((card, index) => (
                        <div key={index} className="relative bg-white rounded-2xl shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2">
                            {/* Badge de destacado */}
                            <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                                <span className="bg-blue-600 text-white px-4 py-1 rounded-full text-sm font-semibold">
                                    {card.highlight}
                                </span>
                            </div>

                            <div className="p-8">
                                {/* Encabezado */}
                                <div className="flex items-center gap-3 mb-4">
                                    <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                                        {card.icon}
                                    </div>
                                    <div>
                                        <h3 className="text-xl font-bold">{card.title}</h3>
                                        <p className="text-gray-600">{card.description}</p>
                                    </div>
                                </div>

                                {/* Precio */}
                                <div className="text-center my-6">
                                    <span className="text-4xl font-bold text-blue-600">
                                        ${card.price}
                                    </span>
                                </div>

                                {/* Características */}
                                <ul className="space-y-3 mb-6">
                                    {card.features.map((feature, i) => (
                                        <li key={i} className="flex items-center gap-2">
                                            <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0" />
                                            <span>{feature}</span>
                                        </li>
                                    ))}
                                </ul>

                                {/* Beneficios y garantía */}
                                <div className="bg-blue-50 p-4 rounded-lg mb-6">
                                    <div className="flex items-center gap-2 mb-2">
                                        <ThumbsUp className="w-5 h-5 text-blue-600" />
                                        <span className="font-semibold">{card.benefits}</span>
                                    </div>
                                    <div className="flex justify-between text-sm text-gray-600">
                                        <span>⏱️ {card.timeEstimate}</span>
                                        <span>🛡️ Garantía: {card.warranty}</span>
                                    </div>
                                </div>

                                {/* CTA */}
                                <button className="w-full bg-blue-600 text-white py-3 rounded-lg font-semibold hover:bg-blue-700 transition duration-300">
                                    Solicitar Servicio
                                </button>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Sección de confianza */}
                <div className="mt-16 text-center">
                    <p className="text-2xl font-semibold mb-6">¿Por qué más de 1,000 clientes confían en nosotros?</p>
                    <div className="grid md:grid-cols-4 gap-6">
                        <div className="bg-white p-4 rounded-lg shadow-md">
                            <div className="text-3xl font-bold text-blue-600 mb-2">98%</div>
                            <div className="text-gray-600">Satisfacción del cliente</div>
                        </div>
                        <div className="bg-white p-4 rounded-lg shadow-md">
                            <div className="text-3xl font-bold text-blue-600 mb-2">24/7</div>
                            <div className="text-gray-600">Servicio de emergencia</div>
                        </div>
                        <div className="bg-white p-4 rounded-lg shadow-md">
                            <div className="text-3xl font-bold text-blue-600 mb-2">15+</div>
                            <div className="text-gray-600">Años de experiencia</div>
                        </div>
                        <div className="bg-white p-4 rounded-lg shadow-md">
                            <div className="text-3xl font-bold text-blue-600 mb-2">100%</div>
                            <div className="text-gray-600">Garantía de servicio</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PricingSection;