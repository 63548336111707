import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import Home from "../Components/Home/Home";
import Navbar from "../Components/Nav/Navbar";
import Buttons from "../Components/Buttons/Buttons";
import Footer from "../Components/Footer/Footer";
import About from "../Components/About/About";
import Services from "../Components/Services/Services";
import Questions from "../Components/questions/Questions";
import { HelmetProvider } from 'react-helmet-async';
import ScrollToTop from "./ScrollToTop";

function App() {

  return (
    <BrowserRouter>
      <HelmetProvider>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sobre-mantenimiento-de-lavadoras-ariza" element={<About />} />
          <Route path="/servicios-de-mantenimiento-lavadoras" element={<Services />} />
          <Route path="/preguntas-frecuentes" element={<Questions />} />
        </Routes>
        <Buttons />
        <Footer />
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;