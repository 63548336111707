import React from 'react';
import {
    Phone,
    Clock,
    Mail,
    MapPin,
    Check,
    Award,
    Users,
    ShieldCheck,
    Wrench,
    Star,
    ThumbsUp,
    Truck,
    Calendar,
    AlertCircle,
    Settings
} from 'lucide-react';
import {Helmet} from 'react-helmet-async';

const About = () => {
    const stats = [
        {value: "15+", label: "Años de Experiencia"},
        {value: "5000+", label: "Clientes Satisfechos"},
        {value: "98%", label: "Satisfacción"},
        {value: "24/7", label: "Soporte"},
    ];

    const brands = [
        "LG", "Samsung", "Whirlpool", "Mabe", "Electrolux", "Haceb",
        "Centrales", "Challenger", "General Electric", "Frigidaire"
    ];

    const services = [
        {
            title: "Mantenimiento Preventivo",
            description: "Servicio completo para prevenir futuras averías y extender la vida útil de su lavadora."
        },
        {
            title: "Reparación de Averías",
            description: "Solución rápida y eficaz para cualquier problema que presente su electrodoméstico."
        },
        {
            title: "Instalación de Repuestos",
            description: "Uso exclusivo de repuestos originales con garantía del fabricante."
        },
        {
            title: "Diagnóstico Especializado",
            description: "Evaluación profesional para identificar con precisión cualquier problema."
        },
        {
            title: "Limpieza Profesional",
            description: "Servicio profundo de limpieza para mantener su lavadora en óptimas condiciones."
        },
        {
            title: "Actualización de Sistemas",
            description: "Modernización y actualización de componentes electrónicos."
        }
    ];

    const commonProblems = [
        {
            problem: "La lavadora no enciende",
            solution: "Diagnóstico completo del sistema eléctrico y panel de control"
        },
        {
            problem: "Ruidos extraños",
            solution: "Revisión de rodamientos, motor y transmisión"
        },
        {
            problem: "No centrifuga",
            solution: "Verificación de correa, motor y sistema de drenaje"
        },
        {
            problem: "Fugas de agua",
            solution: "Inspección de mangueras, sellos y bomba de agua"
        }
    ];

    const testimonials = [
        {
            quote: "Excelente servicio, muy recomendado como dejaron la lavadora!",
            name: "Carlos Perez",
            location: "Soacha, Cundinamarca",
            rating: 5,
            service: "Mantenimiento Preventivo"
        },
        {
            quote: "Muy puntual el señor Jorge. Dejó la lavadora perfecta.",
            name: "Ana Gómez",
            location: "Suba, Bogotá D.C",
            rating: 5,
            service: "Reparación de Motor"
        },
        {
            quote: "Muy profesional el servicio, realmente encantado",
            name: "Juan Rodríguez",
            location: "Chapinero, Bogotá D.C",
            rating: 5,
            service: "Cambio de Repuestos"
        }
    ];

    const benefits = [
        {
            icon: <ShieldCheck className="w-6 h-6"/>,
            title: "Garantía Extendida",
            description: "Hasta 6 meses en repuestos y 3 meses en mano de obra"
        },
        {
            icon: <Clock className="w-6 h-6"/>,
            title: "Respuesta Rápida",
            description: "Atención en menos de 24 horas"
        },
        {
            icon: <Wrench className="w-6 h-6"/>,
            title: "Técnicos Certificados",
            description: "Personal altamente capacitado y certificado"
        },
        {
            icon: <Truck className="w-6 h-6"/>,
            title: "Servicio a Domicilio",
            description: "Nos desplazamos a cualquier zona de Bogotá"
        }
    ];

    return (
        <main className="bg-gray-50">
            <Helmet>
                <title>Mantenimiento y Reparación de Lavadoras en Bogotá | 15 Años de Experiencia</title>
                <meta name="description"
                      content="Servicio técnico profesional de mantenimiento y reparación de lavadoras con 15 años de experiencia en Bogotá. Especialistas en LG, Samsung y Whirlpool. Repuestos originales y garantía en todos nuestros servicios. ⚡ Atención inmediata a domicilio."/>
                <meta name="keywords"
                      content="mantenimiento lavadoras bogota, reparacion lavadoras, servicio tecnico lavadoras, tecnicos certificados, repuestos originales lavadoras, garantia lavadoras, mantenimiento lg bogota, reparacion samsung bogota, servicio whirlpool bogota"/>
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://mantenimientolavadorasariza.com/sobre-mantenimiento-de-lavadoras-ariza"/>

                <meta property="og:title"
                      content="Mantenimiento de Lavadoras Ariza | 15 Años Reparando Lavadoras en Bogotá"/>
                <meta property="og:description"
                      content="Expertos en mantenimiento y reparación de lavadoras. Servicio técnico certificado, repuestos originales y garantía en todo Bogotá. Más de 15 años respaldando nuestro trabajo."/>
                <meta property="og:image"
                      content="https://res.cloudinary.com/dm9pjru06/image/upload/v1726877966/logo_bfq6ip.png"/>
                <meta property="og:url" content="https://mantenimientolavadorasariza.com/sobre-nosotros"/>
                <meta property="og:type" content="website"/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content="Mantenimiento de Lavadoras Ariza | Expertos en Reparación"/>
                <meta name="twitter:description"
                      content="15 años de experiencia en mantenimiento de lavadoras. Servicio técnico profesional con garantía y repuestos originales en Bogotá."/>
                <meta name="twitter:image"
                      content="https://res.cloudinary.com/dm9pjru06/image/upload/v1726877966/logo_bfq6ip.png"/>

                <script type="application/ld+json">
                    {`
            {
                "@context": "https://schema.org",
                "@type": "LocalBusiness",
                "name": "Mantenimiento de Lavadoras Ariza",
                "image": "https://res.cloudinary.com/dm9pjru06/image/upload/v1726877966/logo_bfq6ip.png",
                "description": "Empresa especializada en mantenimiento y reparación de lavadoras con más de 15 años de experiencia en Bogotá. Ofrecemos servicio técnico profesional, repuestos originales y garantía en todos nuestros trabajos.",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "Carrera 92 #129a 22",
                    "addressLocality": "Bogotá",
                    "addressRegion": "Cundinamarca",
                    "postalCode": "110111",
                    "addressCountry": "CO"
                },
                "geo": {
                    "@type": "GeoCoordinates",
                    "latitude": 4.7110,
                    "longitude": -74.0721
                },
                "url": "https://mantenimientolavadorasariza.com",
                "telephone": "+573144658752",
                "priceRange": "$",
                "openingHoursSpecification": [
                    {
                        "@type": "OpeningHoursSpecification",
                        "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
                        "opens": "08:00",
                        "closes": "20:00"
                    },
                    {
                        "@type": "OpeningHoursSpecification",
                        "dayOfWeek": "Saturday",
                        "opens": "09:00",
                        "closes": "18:00"
                    }
                ],
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "reviewCount": "150"
                }
            }
        `}
                </script>
            </Helmet>

            {/* Hero Section with Video Background */}
            <section className="relative bg-gradient-to-r from-blue-600 to-blue-800 py-24">
                <div className="absolute inset-0 bg-black/40"></div>
                <div className="container mx-auto px-4 relative">
                    <div className="max-w-3xl mx-auto text-center text-white">
                        <h1 className="text-4xl md:text-5xl font-bold my-16">
                            Expertos en Mantenimiento de Lavadoras en Bogotá
                        </h1>
                        <p className="text-xl mb-8 text-blue-100">
                            Con más de 15 años de experiencia, somos su mejor opción en reparación y mantenimiento de
                            lavadoras.
                            Servicio profesional, garantizado y con repuestos originales.
                        </p>
                        <div className="flex flex-col sm:flex-row gap-4 justify-center">
                            <button
                                className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-blue-50 transition duration-300">
                                Agendar Servicio
                            </button>
                            <button
                                className="bg-transparent border-2 border-white text-white px-8 py-3 rounded-lg font-semibold hover:bg-white/10 transition duration-300">
                                Contactar WhatsApp
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            {/* Emergency Service Banner */}
            <div className="bg-yellow-500 text-white py-3">
                <div className="container mx-auto px-4 text-center">
                    <p className="flex items-center justify-center gap-2">
                        <AlertCircle className="w-5 h-5"/>
                        ¿Emergencia con su lavadora? Llámenos ahora al +57 314 465 8752 - Servicio de emergencia 24/7
                    </p>
                </div>
            </div>

            {/* Stats Section */}
            <section className="py-16 bg-white">
                <div className="container mx-auto px-4">
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                        {stats.map((stat, index) => (
                            <div key={index} className="text-center">
                                <p className="text-4xl font-bold text-blue-600 mb-2">{stat.value}</p>
                                <p className="text-gray-600">{stat.label}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Why Choose Us Section */}
            <section className="py-16 bg-gray-50">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-12">¿Por qué elegirnos?</h2>
                    <div className="grid md:grid-cols-4 gap-8">
                        {benefits.map((benefit, index) => (
                            <div key={index}
                                 className="bg-white p-6 rounded-xl shadow-lg text-center hover:shadow-xl transition duration-300">
                                <div
                                    className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4">
                                    {benefit.icon}
                                </div>
                                <h3 className="text-lg font-semibold mb-2">{benefit.title}</h3>
                                <p className="text-gray-600">{benefit.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Services Section with Detailed Information */}
            <section className="py-16 bg-white">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-4">Nuestros Servicios</h2>
                    <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">
                        Ofrecemos una gama completa de servicios de mantenimiento y reparación para todo tipo de
                        lavadoras
                    </p>
                    <div className="grid md:grid-cols-3 gap-8">
                        {services.map((service, index) => (
                            <div key={index}
                                 className="bg-gray-50 p-6 rounded-xl hover:shadow-lg transition duration-300">
                                <div
                                    className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                                    <Settings className="w-6 h-6 text-blue-600"/>
                                </div>
                                <h3 className="text-xl font-semibold mb-3">{service.title}</h3>
                                <p className="text-gray-600">{service.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Brands We Service */}
            <section className="py-16 bg-gray-50">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-12">Marcas que Reparamos</h2>
                    <div className="grid grid-cols-2 md:grid-cols-5 gap-6">
                        {brands.map((brand, index) => (
                            <div key={index}
                                 className="bg-white p-4 rounded-lg text-center shadow-sm hover:shadow-md transition duration-300">
                                <p className="font-semibold text-gray-700">{brand}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Common Problems Section */}
            <section className="py-16 bg-white">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-12">Problemas Comunes y Soluciones</h2>
                    <div className="grid md:grid-cols-2 gap-8">
                        {commonProblems.map((item, index) => (
                            <div key={index} className="bg-gray-50 p-6 rounded-xl">
                                <h3 className="text-xl font-semibold mb-3 flex items-center gap-2">
                                    <Wrench className="w-6 h-6 text-blue-600"/>
                                    {item.problem}
                                </h3>
                                <p className="text-gray-600">{item.solution}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Enhanced Testimonials Section */}
            <section className="py-16 bg-gray-50">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-12">Lo que dicen nuestros clientes</h2>
                    <div className="grid md:grid-cols-3 gap-8">
                        {testimonials.map((testimonial, index) => (
                            <div key={index}
                                 className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition duration-300">
                                <div className="flex items-center mb-4">
                                    {[...Array(testimonial.rating)].map((_, i) => (
                                        <Star key={i} className="w-5 h-5 text-yellow-400 fill-current"/>
                                    ))}
                                </div>
                                <p className="text-gray-600 mb-4">"{testimonial.quote}"</p>
                                <div className="border-t pt-4">
                                    <p className="font-semibold">{testimonial.name}</p>
                                    <p className="text-sm text-gray-500 mb-2">{testimonial.location}</p>
                                    <p className="text-sm text-blue-600">{testimonial.service}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Service Areas */}
            <section className="py-16 bg-white">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-12">Zonas de Cobertura</h2>
                    <div className="grid md:grid-cols-2 gap-12 items-center">
                        <div>
                            <h3 className="text-xl font-semibold mb-6">Prestamos servicio en toda Bogotá y
                                alrededores:</h3>
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <ul className="space-y-2">
                                        <li className="flex items-center gap-2">
                                            <Check className="w-5 h-5 text-blue-600"/> Usaquén
                                        </li>
                                        <li className="flex items-center gap-2">
                                            <Check className="w-5 h-5 text-blue-600"/> Chapinero
                                        </li>
                                        <li className="flex items-center gap-2">
                                            <Check className="w-5 h-5 text-blue-600"/> Santa Fe
                                        </li>
                                        <li className="flex items-center gap-2">
                                            <Check className="w-5 h-5 text-blue-600"/> San Cristóbal
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <ul className="space-y-2">
                                        <li className="flex items-center gap-2">
                                            <Check className="w-5 h-5 text-blue-600"/> Suba
                                        </li>
                                        <li className="flex items-center gap-2">
                                            <Check className="w-5 h-5 text-blue-600"/> Engativá
                                        </li>
                                        <li className="flex items-center gap-2">
                                            <Check className="w-5 h-5 text-blue-600"/> Kennedy
                                        </li>
                                        <li className="flex items-center gap-2">
                                            <Check className="w-5 h-5 text-blue-600"/> Bosa
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="rounded-xl overflow-hidden shadow-lg">
                            {/* ... (todo el código anterior permanece igual hasta el iframe en la sección Service Areas) ... */}

                            <iframe
                                className="w-full h-[400px]"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.262613728137!2d-74.09285822417056!3d4.724383995250738!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f84c31fec6613%3A0x1be3809cf244ec32!2sCra.%2092%20%23129a-22%2C%20Suba%2C%20Rincon%2C%20Bogot%C3%A1!5e0!3m2!1ses-419!2sco!4v1724538236780!5m2!1ses-419!2sco"
                                loading="lazy"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </section>

            {/* Contact Information */}
            <section className="py-16 bg-gray-50">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-12">Información de Contacto</h2>
                    <div className="grid md:grid-cols-3 gap-8">
                        <div className="bg-white p-6 rounded-xl shadow-lg text-center">
                            <div
                                className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4">
                                <Phone className="w-6 h-6 text-blue-600"/>
                            </div>
                            <h3 className="text-xl font-semibold mb-2">Teléfono</h3>
                            <p className="text-gray-600">+57 314 465 8752</p>
                            <p className="text-gray-600">+57 301 123 4567</p>
                        </div>
                        <div className="bg-white p-6 rounded-xl shadow-lg text-center">
                            <div
                                className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4">
                                <Clock className="w-6 h-6 text-blue-600"/>
                            </div>
                            <h3 className="text-xl font-semibold mb-2">Horario de Atención</h3>
                            <p className="text-gray-600">Lunes a Viernes: 8am - 8pm</p>
                            <p className="text-gray-600">Sábados: 9am - 6pm</p>
                        </div>
                        <div className="bg-white p-6 rounded-xl shadow-lg text-center">
                            <div
                                className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4">
                                <MapPin className="w-6 h-6 text-blue-600"/>
                            </div>
                            <h3 className="text-xl font-semibold mb-2">Dirección</h3>
                            <p className="text-gray-600">Carrera 92 #129a 22</p>
                            <p className="text-gray-600">Bogotá, Colombia</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Appointment Scheduling */}
            <section className="py-16 bg-blue-600">
                <div className="container mx-auto px-4">
                    <div className="max-w-3xl mx-auto text-center text-white">
                        <h2 className="text-3xl font-bold mb-6">
                            ¿Necesita un servicio de reparación?
                        </h2>
                        <p className="text-xl mb-8">
                            Programe una cita ahora y reciba atención prioritaria.
                            Primera visita de diagnóstico sin costo.
                        </p>
                        <div className="flex flex-col sm:flex-row gap-4 justify-center">
                            <button
                                className="bg-white text-blue-600 px-8 py-4 rounded-lg font-semibold hover:bg-blue-50 transition duration-300 flex items-center justify-center gap-2">
                                <Calendar className="w-5 h-5"/>
                                Agendar Cita
                            </button>
                            <button
                                className="bg-transparent border-2 border-white text-white px-8 py-4 rounded-lg font-semibold hover:bg-white/10 transition duration-300 flex items-center justify-center gap-2">
                                <Phone className="w-5 h-5"/>
                                Llamar Ahora
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            {/* Quality Guarantee */}
            <section className="py-16 bg-white">
                <div className="container mx-auto px-4 text-center">
                    <div className="max-w-3xl mx-auto">
                        <div
                            className="w-20 h-20 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-6">
                            <ShieldCheck className="w-10 h-10 text-blue-600"/>
                        </div>
                        <h2 className="text-3xl font-bold mb-6">Nuestra Garantía de Calidad</h2>
                        <p className="text-gray-600 mb-8">
                            Respaldamos todos nuestros servicios con una garantía completa.
                            Si no está satisfecho con nuestro trabajo, lo solucionaremos sin costo adicional.
                            Su satisfacción es nuestra prioridad número uno.
                        </p>
                        <div className="flex flex-wrap justify-center gap-4">
                            <div className="flex items-center gap-2">
                                <Check className="w-5 h-5 text-blue-600"/>
                                <span>Garantía en repuestos</span>
                            </div>
                            <div className="flex items-center gap-2">
                                <Check className="w-5 h-5 text-blue-600"/>
                                <span>Garantía en mano de obra</span>
                            </div>
                            <div className="flex items-center gap-2">
                                <Check className="w-5 h-5 text-blue-600"/>
                                <span>Satisfacción garantizada</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default About;