import React from 'react';
import {Helmet} from 'react-helmet-async';
import {
    Phone,
    Clock,
    MapPin,
    ArrowRight,
    Shield,
    CheckCircle,
    AlertTriangle,
    Settings,
    Wrench,
    Package,
    ThumbsUp
} from 'lucide-react';
import tecnico from '../../Assets/images/tecnico.webp'
import tecnico2 from '../../Assets/images/tecnico2.webp'

export default function ServicioLavadoras() {
    return (
        <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
            <Helmet>
                <title>Mantenimiento de Lavadoras en Bogotá | Servicio Técnico Especializado</title>
                <meta
                    name="description"
                    content="Servicio profesional de mantenimiento y reparación de lavadoras a domicilio en Bogotá. Técnicos especializados en todas las marcas: LG, Samsung, Whirlpool, Mabe y más. ¡Agenda ahora!"
                />
                <meta
                    name="keywords"
                    content="mantenimiento lavadoras bogota, reparacion lavadoras, servicio tecnico lavadoras, mantenimiento lg, mantenimiento samsung, reparacion whirlpool, tecnicos lavadoras bogota"
                />
                <meta rel="canonical" href="https://mantenimientolavadorasariza.com/preguntas-frecuentes"/>
            </Helmet>

            {/* Hero Section */}
            <div className="relative bg-blue-900 text-white">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
                    <div className="grid md:grid-cols-2 gap-12 items-center">
                        <div className="space-y-6">
              <span className="inline-block bg-blue-800 px-4 py-2 rounded-full text-sm font-medium">
                Servicio técnico certificado
              </span>
                            <h1 className="text-4xl md:text-5xl font-bold leading-tight">
                                Mantenimiento de Lavadoras en Bogotá
                            </h1>
                            <p className="text-xl text-blue-100">
                                Expertos en reparación y mantenimiento de todas las marcas. Servicio a domicilio con
                                garantía.
                            </p>
                            <div className="flex gap-4 flex-wrap">
                                <a
                                    href="https://wa.link/9wu34j"
                                    className="inline-flex items-center px-6 py-3 bg-green-500 hover:bg-green-600 rounded-lg font-medium transition-colors"
                                >
                                    <span>WhatsApp Directo</span>
                                    <ArrowRight className="ml-2 h-5 w-5"/>
                                </a>
                                <a
                                    href="tel:+576011234567"
                                    className="inline-flex items-center px-6 py-3 bg-blue-700 hover:bg-blue-600 rounded-lg font-medium transition-colors"
                                >
                                    <Phone className="mr-2 h-5 w-5"/>
                                    <span>Llamar Ahora</span>
                                </a>
                            </div>
                            <div className="flex flex-wrap gap-4 pt-4">
                                <div className="flex items-center gap-2">
                                    <CheckCircle className="h-5 w-5 text-green-400"/>
                                    <span>Garantía por escrito</span>
                                </div>
                                <div className="flex items-center gap-2">
                                    <CheckCircle className="h-5 w-5 text-green-400"/>
                                    <span>Repuestos originales</span>
                                </div>
                                <div className="flex items-center gap-2">
                                    <CheckCircle className="h-5 w-5 text-green-400"/>
                                    <span>Técnicos certificados</span>
                                </div>
                            </div>
                        </div>
                        <div className="hidden md:block">
                            <div className="grid grid-cols-2 gap-4">
                                <img src={tecnico} alt="Servicio de lavadoras en Bogotá"
                                     className="rounded-lg shadow-lg transform -rotate-3"/>
                                <img src={tecnico2} alt="Mantenimiento profesional de lavadoras"
                                     className="rounded-lg shadow-lg transform rotate-3 mt-8"/>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Features Section */}
                <div className="bg-blue-800 py-12">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="grid md:grid-cols-4 gap-8">
                            <div className="flex items-center space-x-4">
                                <Clock className="h-8 w-8 text-blue-300"/>
                                <div>
                                    <h3 className="font-semibold">Atención 24/7</h3>
                                    <p className="text-blue-200">Servicio de emergencia</p>
                                </div>
                            </div>
                            <div className="flex items-center space-x-4">
                                <Shield className="h-8 w-8 text-blue-300"/>
                                <div>
                                    <h3 className="font-semibold">Garantía Total</h3>
                                    <p className="text-blue-200">Hasta 6 meses</p>
                                </div>
                            </div>
                            <div className="flex items-center space-x-4">
                                <Wrench className="h-8 w-8 text-blue-300"/>
                                <div>
                                    <h3 className="font-semibold">Personal Experto</h3>
                                    <p className="text-blue-200">Técnicos certificados</p>
                                </div>
                            </div>
                            <div className="flex items-center space-x-4">
                                <Package className="h-8 w-8 text-blue-300"/>
                                <div>
                                    <h3 className="font-semibold">Repuestos</h3>
                                    <p className="text-blue-200">100% originales</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
                {/* Brands Section */}
                <section className="mb-20">
                    <h2 className="text-3xl font-bold text-blue-900 mb-6">Especialistas en Todas las Marcas</h2>
                    <p className="text-gray-600 mb-8">
                        Nuestros técnicos están certificados y especializados en el mantenimiento y reparación de las
                        principales marcas del mercado.
                    </p>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                        <div className="bg-white p-6 rounded-xl shadow-lg text-center">
                            <h3 className="text-xl font-semibold text-blue-800">Samsung</h3>
                            <p className="text-gray-600 mt-2">Servicio autorizado</p>
                        </div>
                        <div className="bg-white p-6 rounded-xl shadow-lg text-center">
                            <h3 className="text-xl font-semibold text-blue-800">LG</h3>
                            <p className="text-gray-600 mt-2">Técnicos certificados</p>
                        </div>
                        <div className="bg-white p-6 rounded-xl shadow-lg text-center">
                            <h3 className="text-xl font-semibold text-blue-800">Whirlpool</h3>
                            <p className="text-gray-600 mt-2">Repuestos originales</p>
                        </div>
                        <div className="bg-white p-6 rounded-xl shadow-lg text-center">
                            <h3 className="text-xl font-semibold text-blue-800">Mabe</h3>
                            <p className="text-gray-600 mt-2">Garantía certificada</p>
                        </div>
                    </div>
                </section>

                {/* Services Section */}
                <section className="mb-20">
                    <h2 className="text-3xl font-bold text-blue-900 mb-6">Servicios Especializados de Mantenimiento</h2>
                    <p className="text-gray-600 mb-12">
                        Ofrecemos soluciones completas para el mantenimiento y reparación de tu lavadora en Bogotá.
                    </p>
                    <div className="grid md:grid-cols-3 gap-8">
                        <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
                            <Settings className="h-12 w-12 text-blue-600 mb-4"/>
                            <h3 className="text-xl font-semibold text-blue-800 mb-4">Mantenimiento Preventivo</h3>
                            <ul className="space-y-3 text-gray-600">
                                <li className="flex items-start gap-2">
                                    <CheckCircle className="h-5 w-5 text-green-500 mt-1 flex-shrink-0"/>
                                    <span>Limpieza profunda de agitador y tinas</span>
                                </li>
                                <li className="flex items-start gap-2">
                                    <CheckCircle className="h-5 w-5 text-green-500 mt-1 flex-shrink-0"/>
                                    <span>Revisión y ajuste de correa</span>
                                </li>
                                <li className="flex items-start gap-2">
                                    <CheckCircle className="h-5 w-5 text-green-500 mt-1 flex-shrink-0"/>
                                    <span>Limpieza de filtros y bombas</span>
                                </li>
                                <li className="flex items-start gap-2">
                                    <CheckCircle className="h-5 w-5 text-green-500 mt-1 flex-shrink-0"/>
                                    <span>Verificación de sistemas eléctricos</span>
                                </li>
                            </ul>
                        </div>

                        <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
                            <Wrench className="h-12 w-12 text-blue-600 mb-4"/>
                            <h3 className="text-xl font-semibold text-blue-800 mb-4">Reparación Especializada</h3>
                            <ul className="space-y-3 text-gray-600">
                                <li className="flex items-start gap-2">
                                    <CheckCircle className="h-5 w-5 text-green-500 mt-1 flex-shrink-0"/>
                                    <span>Diagnóstico electrónico completo</span>
                                </li>
                                <li className="flex items-start gap-2">
                                    <CheckCircle className="h-5 w-5 text-green-500 mt-1 flex-shrink-0"/>
                                    <span>Reparación de tarjetas y módulos</span>
                                </li>
                                <li className="flex items-start gap-2">
                                    <CheckCircle className="h-5 w-5 text-green-500 mt-1 flex-shrink-0"/>
                                    <span>Cambio de repuestos originales</span>
                                </li>
                                <li className="flex items-start gap-2">
                                    <CheckCircle className="h-5 w-5 text-green-500 mt-1 flex-shrink-0"/>
                                    <span>Corrección de fugas y drenaje</span>
                                </li>
                            </ul>
                        </div>

                        <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
                            <ThumbsUp className="h-12 w-12 text-blue-600 mb-4"/>
                            <h3 className="text-xl font-semibold text-blue-800 mb-4">Servicios Adicionales</h3>
                            <ul className="space-y-3 text-gray-600">
                                <li className="flex items-start gap-2">
                                    <CheckCircle className="h-5 w-5 text-green-500 mt-1 flex-shrink-0"/>
                                    <span>Instalación y configuración</span>
                                </li>
                                <li className="flex items-start gap-2">
                                    <CheckCircle className="h-5 w-5 text-green-500 mt-1 flex-shrink-0"/>
                                    <span>Mantenimiento programado</span>
                                </li>
                                <li className="flex items-start gap-2">
                                    <CheckCircle className="h-5 w-5 text-green-500 mt-1 flex-shrink-0"/>
                                    <span>Asesoría técnica especializada</span>
                                </li>
                                <li className="flex items-start gap-2">
                                    <CheckCircle className="h-5 w-5 text-green-500 mt-1 flex-shrink-0"/>
                                    <span>Garantía extendida</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Common Problems Section */}
                <section className="mb-20">
                    <h2 className="text-3xl font-bold text-blue-900 mb-6">Soluciones para Problemas Comunes</h2>
                    <p className="text-gray-600 mb-12">
                        Identificamos y solucionamos rápidamente los problemas más frecuentes en lavadoras.
                    </p>
                    <div className="grid md:grid-cols-2 gap-8">
                        <div className="bg-white p-8 rounded-xl shadow-lg">
                            <h3 className="text-xl font-semibold text-blue-800 mb-6">Problemas Mecánicos</h3>
                            <div className="space-y-6">
                                <div className="flex items-start gap-4">
                                    <AlertTriangle className="h-6 w-6 text-yellow-500 flex-shrink-0 mt-1"/>
                                    <div>
                                        <h4 className="font-medium text-gray-900 mb-2">Ruidos Extraños</h4>
                                        <p className="text-gray-600">Identificación y reparación de problemas en
                                            rodamientos, motor o transmisión.</p>
                                    </div>
                                </div>
                                <div className="flex items-start gap-4">
                                    <AlertTriangle className="h-6 w-6 text-yellow-500 flex-shrink-0 mt-1"/>
                                    <div>
                                        <h4 className="font-medium text-gray-900 mb-2">Problemas de Centrifugado</h4>
                                        <p className="text-gray-600">Corrección de desbalance y ajuste de sistema de
                                            centrifugado.</p>
                                    </div>
                                </div>
                                <div className="flex items-start gap-4">
                                    <AlertTriangle className="h-6 w-6 text-yellow-500 flex-shrink-0 mt-1"/>
                                    <div>
                                        <h4 className="font-medium text-gray-900 mb-2">Problemas de Centrifugado</h4>
                                        <p className="text-gray-600">Corrección de desbalance y ajuste de sistema de
                                            centrifugado.</p>
                                    </div>
                                </div>
                                <div className="flex items-start gap-4">
                                    <AlertTriangle className="h-6 w-6 text-yellow-500 flex-shrink-0 mt-1"/>
                                    <div>
                                        <h4 className="font-medium text-gray-900 mb-2">Fugas de Agua</h4>
                                        <p className="text-gray-600">Detección y reparación de fugas en mangueras y
                                            sellos.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white p-8 rounded-xl shadow-lg">
                            <h3 className="text-xl font-semibold text-blue-800 mb-6">Problemas Eléctricos</h3>
                            <div className="space-y-6">
                                <div className="flex items-start gap-4">
                                    <AlertTriangle className="h-6 w-6 text-yellow-500 flex-shrink-0 mt-1"/>
                                    <div>
                                        <h4 className="font-medium text-gray-900 mb-2">No Enciende</h4>
                                        <p className="text-gray-600">Diagnóstico y reparación de problemas eléctricos y
                                            tarjetas.</p>
                                    </div>
                                </div>
                                <div className="flex items-start gap-4">
                                    <AlertTriangle className="h-6 w-6 text-yellow-500 flex-shrink-0 mt-1"/>
                                    <div>
                                        <h4 className="font-medium text-gray-900 mb-2">Panel de Control</h4>
                                        <p className="text-gray-600">Reparación de botones y display digital.</p>
                                    </div>
                                </div>
                                <div className="flex items-start gap-4">
                                    <AlertTriangle className="h-6 w-6 text-yellow-500 flex-shrink-0 mt-1"/>
                                    <div>
                                        <h4 className="font-medium text-gray-900 mb-2">Ciclos Incompletos</h4>
                                        <p className="text-gray-600">Corrección de programación y sensores.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Coverage Area Section */}
                <section className="mb-20 bg-blue-50 rounded-xl p-8">
                    <h2 className="text-3xl font-bold text-blue-900 mb-6">Zonas de Cobertura</h2>
                    <p className="text-gray-600 mb-8">
                        Brindamos servicio técnico de lavadoras a domicilio en toda el área metropolitana de Bogotá y
                        municipios aledaños.
                    </p>
                    <div className="grid md:grid-cols-3 gap-8">
                        <div className="bg-white p-6 rounded-lg shadow">
                            <h3 className="font-semibold text-blue-800 mb-4">Bogotá Norte</h3>
                            <ul className="space-y-2 text-gray-600">
                                <li>• Usaquén</li>
                                <li>• Suba</li>
                                <li>• Chapinero</li>
                                <li>• Santa Bárbara</li>
                            </ul>
                        </div>
                        <div className="bg-white p-6 rounded-lg shadow">
                            <h3 className="font-semibold text-blue-800 mb-4">Bogotá Centro</h3>
                            <ul className="space-y-2 text-gray-600">
                                <li>• Teusaquillo</li>
                                <li>• Barrios Unidos</li>
                                <li>• Fontibón</li>
                                <li>• Engativá</li>
                            </ul>
                        </div>
                        <div className="bg-white p-6 rounded-lg shadow">
                            <h3 className="font-semibold text-blue-800 mb-4">Municipios Cercanos</h3>
                            <ul className="space-y-2 text-gray-600">
                                <li>• Chía</li>
                                <li>• Cota</li>
                                <li>• La Calera</li>
                                <li>• Mosquera</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Why Choose Us Section */}
                <section className="mb-20">
                    <h2 className="text-3xl font-bold text-blue-900 mb-6">¿Por Qué Elegirnos?</h2>
                    <div className="grid md:grid-cols-3 gap-8">
                        <div className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
                            <Shield className="h-12 w-12 text-blue-600 mb-4"/>
                            <h3 className="text-xl font-semibold text-blue-800 mb-3">Garantía de Servicio</h3>
                            <p className="text-gray-600">
                                Respaldamos nuestro trabajo con hasta 6 meses de garantía por escrito en repuestos y
                                mano de obra.
                            </p>
                        </div>
                        <div className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
                            <Wrench className="h-12 w-12 text-blue-600 mb-4"/>
                            <h3 className="text-xl font-semibold text-blue-800 mb-3">Técnicos Certificados</h3>
                            <p className="text-gray-600">
                                Personal altamente capacitado y certificado por las principales marcas del mercado.
                            </p>
                        </div>
                        <div className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
                            <Package className="h-12 w-12 text-blue-600 mb-4"/>
                            <h3 className="text-xl font-semibold text-blue-800 mb-3">Repuestos Originales</h3>
                            <p className="text-gray-600">
                                Utilizamos exclusivamente repuestos originales con garantía de fábrica.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Contact Section */}
                <section className="mt-20 bg-blue-50 rounded-xl p-8">
                    <div className="grid md:grid-cols-2 gap-12">
                        <div>
                            <h2 className="text-2xl font-bold text-blue-900 mb-6">¿Necesitas un Servicio de
                                Mantenimiento?</h2>
                            <p className="text-gray-600 mb-8">
                                Contamos con técnicos especializados listos para ayudarte. Atendemos todas las marcas y
                                modelos de lavadoras.
                            </p>
                            <div className="space-y-4">
                                <div className="flex items-center space-x-4">
                                    <Phone className="h-6 w-6 text-blue-600"/>
                                    <span className="text-gray-600">Llámanos: (601) 123-4567</span>
                                </div>
                                <div className="flex items-center space-x-4">
                                    <MapPin className="h-6 w-6 text-blue-600"/>
                                    <span className="text-gray-600">Servicio a domicilio en toda Bogotá</span>
                                </div>
                                <div className="flex items-center space-x-4">
                                    <Clock className="h-6 w-6 text-blue-600"/>
                                    <span className="text-gray-600">Atención 24/7 para emergencias</span>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white p-6 rounded-lg shadow-lg">
                            <h3 className="text-xl font-semibold text-blue-800 mb-6">Agenda tu servicio ahora</h3>
                            <div className="space-y-4">
                                <a
                                    href="https://wa.link/9wu34j"
                                    className="block w-full text-center bg-green-500 text-white py-3 rounded-lg hover:bg-green-600 transition-colors"
                                >
                                    Contactar por WhatsApp
                                </a>
                                <a
                                    href="tel:+576011234567"
                                    className="block w-full text-center bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition-colors"
                                >
                                    Llamar Ahora
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}